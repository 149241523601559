.draft{
    color:#FCA440;
    background: rgba(254, 225, 191, 0.25);
    border-color: #FCA440;
}
.underReview{
    color:#0AAFFF;
    background: rgba(206, 239, 255, 0.25);
    border-color: #0AAFFF;
}
.live{
    color:#24A25C;
    background: rgba(213, 244, 227, 0.25);
    border-color: #24A25C;
}
.upcoming{
    color:#FCA440;
    background: rgba(254, 225, 191, 0.25);
    border-color: #FCA440;
}
.completed{
    color:#494646;
    background: #F9FAFC;
    border-color: #494646;
}
.rejected{
    color:#be0b44;
    background: rgba(206, 239, 255, 0.25);
    border-color: #be0b44;
}