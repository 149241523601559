.main-panel {
  display: flex;
  width: 100%;
  height: 69px;
  background: #ffffff;
  box-shadow: 0px 6px 24px 5px rgb(0 0 0 / 2%);
  border-radius: 12px;
  justify-content: center;
  align-content: space-between;
  align-items: center;
}
.sub-menu {
  height: 69px;
}
.category-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;

  position: static;
  width: 59px;
  height: 26px;
  left: 0px;
  top: 0px;

  border: 1px solid #e5e9f2;
  box-sizing: border-box;
  border-radius: 29px;
}
.purple-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;

  position: static;

  background: #eeebf5;
  border-radius: 4px;
  color: #513a98;
  margin: 0px 8px;
}
.plain-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;

  position: static;

  background: transparent;
  border-radius: 4px;
  color: #1c2d41;
  margin: 0px 8px;
}
.purple-button svg {
  margin-right: 5px;
}
.nav-panel .active {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  /* color: #1c2d41 !important; */

  /* Night Sky/25% */

  /* background: rgba(210, 213, 217, 0.25) !important; */
  border-radius: 6px !important;
}
.active .Img {
  filter: invert(2.2) sepia(2) saturate(0) hue-rotate(306deg) brightness(1.2);
}
.nav-panel .nav-item a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Night Sky/100% */

  color: #1c2d41;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.admin-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
  width: 80px;
  height: 26px;
}
.tag-green {
  background: rgba(213, 244, 227, 0.25);
  color: #24a25c;
}
.tag-blue {
  background: rgba(206, 239, 255, 0.25);
  color: #0aafff;
}
.tag-red {
  background: rgba(250, 216, 216, 0.5);
  color: #e73c3c;
}
.grey-active {
  background: rgba(210, 213, 217, 0.25) !important;
  border-radius: 4px;
  color: #1c2d41 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.tracking-btn {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 184px;
  background: rgba(210, 213, 217, 0.25);
  border-radius: 4px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* CSS for the online and offline status */
.online-status {
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Styles for online status */
.online {
  color: green;
}

/* Styles for offline status */
.offline {
  color: red;
}

