.product-carousel {
    position: relative;
    padding: 10px;
    width: 460px;
    height: 500px;
}

.product-container {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.pre-btn,
.next-btn {
    border: none;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

.pre-btn {
    left: 0;
}

.next-btn {
    right: 0;
}

.next-btn p,
.pre-btn p {
    font-size: 25px;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    width: 25px;
    height: 25px;
    cursor: pointer;
}