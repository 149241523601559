.mycard {
    min-width: 460px;
    max-width: 460px;
    width: 460px;
    height: 500px;
    min-height: 500px;
    max-height: 500px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.innerimg{
    max-width: 460px;
    max-height: 500px;
}