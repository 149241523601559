
.view-calendar{
  height: 80vh !important;
}
.card-body{
  height: 80vh !important;
}
.cal {
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.filters {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #1C2D41;
}
.view-calendar {
  width: 97.5%;
  height: 874px;
  margin: 20px 0;
  background-color: #FFFFFF;
  border-radius: 12px; 
}

.modal-card {
  width: 500px;
  padding: 24px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.modal-card-title {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.modal-card-title > img {
  width: 40px;
  height: 40px;
  border-radius: 60px;
}
.modal-card-title > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}
.modal-card-title > div > h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1C2D41;
  margin: 0;
}
.modal-card-title > div > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #495767;
  margin: 0;
}
.modal-card-body {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.modal-card-bodyL {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
  height: 150px;
}
.modal-card-bodyL > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #1C2D41;
  margin: 0;
}
.modal-card-bodyR {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 296px;
  height: 150px;
}
.modal-card-bodyR > div {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #1C2D41;
}
.modal-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 12px;
}
.modal-card-footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 140px;
}
.modal-card-footer > div > h2 {
  font-weight: 600;
  color: #1C2D41;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.modal-card-footer > div > p {
  font-weight: normal; 
  color: #8E96A0;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.modal-card > button {
  width: 130px;
}

.fc-button {
  background-color: #F9FAFC !important;
  color: #1C2D41 !important;
  border: none !important;
}
.fc-button-active {
  background-color: #513A98 !important;
  color: #FFFFFF !important;
  border: none !important;
}
.fc-day-today{
  background-color: #FFFFFF !important;
}
.fc-daygrid-event {
  border: none !important;
  border-radius: 4px !important;
}
.fc-timegrid-event-harness {
  width: 1255px !important;
}
.fc-timegrid-event {
  border: none !important;
  border-radius: 4px !important;
  height: fit-content;
}
.fc-daygrid-event-harness {
  overflow: hidden !important;
}
/* .fc-timegrid-slot {
  height: 23px !important;
} */
.fc-scrollgrid {
  border: none !important;
}
.fc-timegrid-axis {
  border: none !important;
}
.fc-timegrid-col {
  overflow: hidden !important;
}
.fc-scrollgrid-section-body>td,
.fc-scrollgrid-section-header>th{
  border: none !important;
}
.fc-timegrid-divider{
  display: none !important;
}

.event-container {
  width: 100% !important;
  height: 66px !important;
  padding: 12px 16px !important;
  justify-content: space-between;
  align-items: center;
}
.event-danger {
  background: rgba(250, 216, 216, 0.5) !important;
}
.event-warning {
  background: rgba(249, 241, 213, 0.5) !important;
}
.event-info {
  background: rgba(206, 239, 255, 0.25) !important;
}
.event-left > img {
  width: 40px;
  height: 40px;
  border-radius: 60px;
  margin-right: 16px;
}
.event-left > div > h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1C2D41;
  margin: 0;
}
.event-left > div > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #495767;
  margin: 0;
}
.event-right {
  width: 441px;
  justify-content: space-between;
  align-items: center;
  color: #1C2D41;
}
.event-item > p {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #8E96A0;
  margin: 0;
} 
.event-item > h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1C2D41;
  margin: 0;
}
.event-container-small {
  width: 100% !important;
  padding: 4px 8px !important;
  align-items: center;
  justify-content: flex-start;
}
.event-container-small > img {
  width: 16px;
  height: 16px;
  border-radius: 60px;
  margin-right: 4px;
}
.event-container-small > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1C2D41;
  margin: 0;
  text-align: center;
}
.fc-toolbar-chunk>div{
  display: flex;
}
.calenderLabel
{
  background-color: red;
}
.fc-timegrid-body{
  width: 100% !important;
}
.view-calendar table tr th:last-child{
  padding: 0% !important;
}
.view-calendar table tr td:last-child{
  padding: 0% !important;
}
.fc-col-header {
  margin: 0% !important;
  width: 100% !important;
}
.fc .fc-scrollgrid table{
  width: 100% !important;
}
.fc-timegrid-event-harness{
  width: 100% !important;
}
.fc-theme-standard td, .fc-theme-standard th{
  border: 0 !important;
}
/* .fc-theme-standard td:nth-child(2){
  border-bottom: 1px solid #000 !important;
} */
.fc-scrollgrid-section-header {
  /* visibility: hidden !important; */
}
.fc .fc-timegrid-slot{
  height: 33px !important;
}
.fc-prev-button {
  background-color: #FFFFFF !important;
  margin-right: 8px !important;

}
.fc-next-button {
  background-color: #FFFFFF !important;
  margin-left: 8px !important;

}
.fc .fc-button{
  box-shadow: 0 0 0 0 !important;
}
.fc-button-active:focus{
  border: 0;
  box-shadow: 0 0 0 0 !important;
}
.fc-toolbar-title{
  font-size: 18px !important;
  display: flex;
  padding-top: 5px;
}
.fc-timeGridDay-button{
  height: 40px !important;
  width: 60px !important;
  border-radius: 8px 0px 0px 8px !important;
}
.fc-dayGridWeek-button{
  height: 40px !important;
  width: 60px !important;
}
.fc-dayGridMonth-button{
  height: 40px !important;
  width: 60px !important;
  border-radius: 0px 8px 8px 0px !important;
}
.fc .fc-daygrid-day-frame{
  border: 1px solid rgba(210, 213, 217, 0.25) !important;
}
.fc-col-header-cell-cushion {
  width: 100% !important;
padding: 14px 24px !important;
height: 50px !important;
background-color: #F9FAFC !important;
border-left: 1px solid #D2D5D940;
}
.fc .fc-daygrid-day-frame{
  min-height: 100px !important;
}