//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #3699ff, #39296A);
$primary-active: if(isDarkMode(), #187de4, #261E3C);
$primary-light: if(isDarkMode(), #212e48, #B7B1C9);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #0bb783, #2DCA73);
$success-active: if(isDarkMode(), #04aa77, #17653A);
$success-light: if(isDarkMode(), #1c3238, #96E5B9);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #0AAFFF);
$info-active: if(isDarkMode(), #7337ee, #055880);
$info-light: if(isDarkMode(), #2f264f, #85D7FF);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #E73C3C);
$danger-active: if(isDarkMode(), #ee2d41, #741E1E);
$danger-light: if(isDarkMode(), #3a2434, #F39E9E);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

$secondary: #CF427E ; // Bootstrap variable
$secondary-active: #68213F ; // Custom variable
$secondary-light: #E7A1BF ; // Custom variable
$secondary-inverse: #ffffff ; // Custom variable
$secondary-background:#F2F4F9 ;//Custom variable

//Summary icon color
$light: #CEEFFF ; // Bootstrap variable
$light-active: #CEEFFF ; // Custom variable
$light-light: #CEEFFF; // Custom variable
$light-inverse: #088CCC ; // Custom variable
$light-background:#F2F4F9 ;//Custom variable

$white: #ffffff ;
$black: #000000 ;
$gray-100: if(isDarkMode(), #1b1b29, #f5f8fa) ;
$gray-200: if(isDarkMode(), #2b2b40, #eff2f5) ;
$gray-300: if(isDarkMode(), #323248, #e4e6ef) ;
$gray-400: if(isDarkMode(), #474761, #b5b5c3) ;
$gray-500: if(isDarkMode(), #565674, #a1a5b7) ;
$gray-600: if(isDarkMode(), #6d6d80, #7e8299) ;
$gray-700: if(isDarkMode(), #92929f, #5e6278) ;
$gray-800: if(isDarkMode(), #cdcdde, #3f4254) ;
$gray-900: if(isDarkMode(), #ffffff, #181c32) ;
$text-muted: $gray-500 ;

// Bootstrap gray colors map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
) ; // Custom variable

// Bootstrap custom colors
$blue: #009ef6 ;
$indigo: #6610f2 ;
$purple: #6f42c1 ;
$pink: #d63384 ;
$red: #dc3545 ;
$orange: #fd7e14 ;
$yellow: #ffc107 ;
$green: #198754 ;
$teal: #20c997 ;
$cyan: #0dcaf0 ;

// Bootstrap colors map
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'gray': $gray-600,
  'gray-dark': $gray-800, 
) ;