@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@700&family=Roboto:wght@700&display=swap');

.Dashboard:active{
    background-color: blue;
    color: brown;
}
.Masters:active{
    background-color:cadetblue;
    color: cyan;
}
.BottomMenuCategories{
    width: 99px;
    height:44px;
    padding:12px, 24px, 12px, 24px;
    top:14px;
    left:120px;
}
/* .UserMenu{
   margin-right: 70px;
} */
@media (max-width:1550px){
    .UserMenu{
        margin-right: 0;
    }
}