:root {
}

/*  AgencyViewprofile No Data CSS */
.No_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 16px;
  width: 100%;
  height: auto;
}

.No_data .img {
  width: 32px;
  height: 32px;
  margin-bottom: 20px;
}

.No_data .content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 783px;
  height: 45px;
}

.content-box h2 {
  color: #1c2d41;
  font-weight: 600;
  font-size: 16px;
}

.content-box p {
  color: #495767;
  font-weight: 400;
  font-size: 14px;
}

.content-box .add_brand_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 199px;
  height: 45px;
  background: #e72281;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

/*  AgencyViewprofile No Data CSS End */

.campaign-select > option {
  margin: 40px !important;
  padding: 20px !important;
  font-size: 16px !important;
}

.campaign-select > option:hover {
  background-color: lightblue !important;
  color: #000 !important;
}

.service-btn {
  border: 1px solid #513a98;
  box-sizing: border-box;
  border-radius: 6px;
  color: #513a98;
  background-color: white;
  padding: 12px 20px;
  font-weight: 600;
}

.align-center {
  text-align: center;
}

.light-bg {
  background-color: #d2d5d940;
}

.dark-bg {
  background-color: #1c2d41;
}

.chatInnerDiv {
  height: 30vh !important;
}

.h-150px {
  height: 150px;
}

.modal-width {
  width: 542px !important;
}

.maxLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.pointer:hover {
  cursor: pointer;
}

.tab-bg {
  background-color: #f9fafc;
}

.white-bg {
  background-color: white;
}

.black-bg {
  padding: 4px 8px;
  background-color: #1c2d41;
  color: white;
}

.bg-custom {
  padding: 12px 20px;
  width: fit-content;
  height: fit-content;
  background-color: #f9fafc;
  border-radius: 50px !important;
}

.fs-20 {
  font-size: 20px;
  font-weight: 600;
}

.fs-14 {
  font-size: 14px;
  font-weight: 400;
}

.fs-16 {
  font-size: 16px !important;
  font-weight: 600;
}

.fs-12 {
  font-size: 12px;
  font-weight: 400;
}

.fs-18 {
  font-size: 18px;
  font-weight: 600;
}

.fw-bold {
  font-weight: 600 !important;
}

.arrow {
  justify-self: flex-end;
}

.bg-grey {
  background-color: #f2f4f9;
}

.f18 {
  font-size: 18px;
  font-weight: 600;
}

.f16 {
  font-size: 16px;
  font-weight: 600;
}

.f14 {
  font-size: 14px;
  font-weight: 400;
}

.content-item {
  padding: 24px;
  width: 1113px;
  height: 323px;
  background-color: white;
  margin-bottom: 22px;
  border-radius: 12px;
}

.content-img {
  width: 163px;
  height: 213px;
}

.content-cards {
  margin-top: 20px;
  margin-left: 20px;
  align-self: start;
  position: sticky;
  top: 0;
}

.influencer-name {
  font-weight: 600;
  font-size: 16px;
}

.influencer-location {
  font-weight: 400;
  font-size: 12px;
}

.search-input {
  height: 42px;
  padding: 16px;
}

.list-item {
  height: 80px;
  padding-left: 16px;
}

.list-item:hover {
  background-color: #f2f4f9;
}

.List {
  width: 405px;
  margin-left: 20px;
  margin-top: 20px;
}

.influencers-list {
  background-color: white !important;
  margin-top: 12px;
}

.profile-photo {
  width: 48px !important;
  height: 48px !important;
}

.w200 {
  width: 200px !important;
}

.w240 {
  width: 240px !important;
}

.basicDiv {
  margin-top: 20px;
  padding: 24px;
  height: auto;
  border-radius: 12px;
  background-color: white;
}

.basicDiv2 {
  margin-top: 20px;
  padding: 0px;
  height: fit-content;
  border-radius: 12px;
  background-color: white;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jEnd {
  display: flex;
  justify-content: right;
}

.navBar {
  background-color: #f2f4f9 !important;
  width: 100%;
  height: 77px;
  padding: 14px;
}

.categorySelect {
  position: relative;
  /* z-index: 0; */
}

.RecentDiv {
  width: 90%;
}

.RecentSummary {
  width: 30%;
}

.RecentSummaryChild {
  width: 42.5%;
  height: 134px;
  border-radius: 12px;
  background-color: white;
  margin-top: 20px;
  margin-left: 20px;
  padding-top: 24px;
  padding-left: 24px;
}

.SummaryDiv {
  width: 80%;
  margin-left: 20px;
  margin-top: 16px;
  margin-right: 12px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
}

.TableHeader {
  width: 100%;
  padding: 24px;
  height: 77px;
  display: flex;
  align-items: center;
}

*:disabled {
  cursor: not-allowed !important;
}

.TableHeaderSearch {
  width: 100%;
}

.TableDiv {
  height: 100%;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: white;
  width: 97%;
  border-radius: 12px;
}

.menuDots {
  display: flex;
  float: right;
  top: -15px;
  margin-right: 8%;
  width: 5px;
  height: 16px;
  padding: 0;
}

.Outer {
  padding-left: 0;
  width: 100%;
}

.BottomMenuCategories:focus {
  background-color: #e5e9f2;
  color: #403f3f;
}

.BrandSteps {
  width: 284px;
  margin-top: 32px;
  height: 232px;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  padding-right: 6px !important;
}

.BrandLine {
  position: absolute;
  z-index: 1;
}

.BrandIcon {
  position: relative;
  z-index: 2;
}

.CompanyDetailsOuter {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.CompanyDetails {
  padding: 32px;
  margin: 20px;
  width: 971px;
  border-radius: 12px;
  background-color: white;
  border-radius: 12px;
}

.CompanyDetailButton {
  width: 172px;
  height: 53px;
}

.paginationButton {
  height: 32px;
  width: 32px;
  margin-right: 4px;
}

.pointer {
  cursor: pointer;
}

.addCategory {
  width: 405px;
  height: fit-content;
  background-color: white;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 12px;
  padding: 32px;
}

.categoryTable {
  width: 830px;
  height: auto;
  background-color: white;
  margin-top: 20px;
  border-radius: 12px;
}

.categoryIcon {
  width: 341px;
  height: 124px;
}

.categoryButton {
  height: 53px;
  min-width: 152.5px;
}

.dialogButton {
  height: 40px;
  min-width: 100px;
}

.categoryTableHeader {
  padding-top: 7px;
  display: flex;
  align-items: center;
}

.addBrand {
  width: 405px;
  height: fit-content;
  background-color: white;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 12px;
  padding: 32px;
}

.socialTable {
  width: 830px;
  height: auto;
  background-color: white;
  margin-top: 20px;
  border-radius: 12px;
}

.priceTableDiv {
  width: 1255px;
  margin-left: 0px;
}

.otherCard {
  width: 100%;
  height: 50px;
}

.my-modal {
  max-width: 400px;
}

.documentList {
  width: 405px;
  height: fit-content;
  padding: 32px;
  margin-top: 24px;
}

.addDocument {
  width: 830px;
  height: 771px;
  margin-top: 24px;
  padding: 32px;
}

.CampaignSteps {
  height: 304px;
  width: 405px !important;
}

.campaignDetails {
  width: 972px;
  height: auto;
  padding: 32px;
}

.influencerDetailsImage {
  width: 24px;
  height: 24px;
}

hr {
  height: 1px !important;
  color: #d6d6d6 !important;
}

.influencerTypeLabel {
  width: 85px !important;
}

.campaignGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 1255px;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 20px;
  margin-top: 18px;
}

.campaignGrid div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.ant-pagination-item {
  border-radius: 6px !important;
  border: white !important;
  background-color: #f9fafc !important;
  font-size: 14px;
  font-weight: bold;
  color: #b5b5c3;
}

.ant-pagination-item:focus {
  color: black !important;
}

.ant-pagination-item-link {
  border-radius: 6px !important;
  border: white !important;
  background-color: #f9fafc !important;
}

.TableButton {
  width: 315px;
  height: 49px;
}

.crop-parent {
  position: absolute;
  height: 100%;
  z-index: 2;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.4);
}

.crop-body {
  position: absolute;
  height: 60%;
  z-index: 3;
  top: 20%;
  left: 30%;
  right: 30%;
  bottom: 20%;
  background-color: #fff;
}

.crop-container {
  position: absolute;
  top: 2%;
  left: 2%;
  right: 2%;
  bottom: 100px;
}

.controls {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  background-color: #3f51b5;
  width: 100%;
  border-radius: 10px;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.css-1pahdxg-control {
  border: 0 !important;
  background-color: #f5f8fa !important;
  color: #b5b5c3 !important;
  border-radius: 6px;
  box-shadow: 0 0 0 0px #fff !important;
}

.css-1s2u09g-control {
  border: 0 !important;
  background-color: #f5f8fa !important;
  color: #b5b5c3 !important;
  border-radius: 6px;
}

.css-6j8wv5-Input {
  background-color: rgba(0, 0, 0, 0) !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #39296a !important;
  color: white;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: #fff !important;
}

.css-1rhbuit-multiValue {
  background-color: #39296a !important;
  border-radius: 5px;
}

.css-1rhbuit-multiValue div {
  background-color: #39296a !important;
  color: #fff !important;
  border-radius: 5px;
}

.css-1okebmr-indicatorSeparator {
  visibility: hidden !important;
}

.btn-primary {
  background-color: #e72281 !important;
}

.create-btn {
  background-color: #e72281 !important;
  color: #fff !important;
}

.create-btn:hover {
  background-color: #741141 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
  transition: ease-in;
}

.sort {
  transform: rotate(90deg);
  transition: transform 2s;
}

.TransGraph {
  width: 263px !important;
  height: auto;
  padding: 16px;
}

.chart-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-bar__inner {
  height: 100%;
  width: 8px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chart-bar__fill {
  background-color: #2dca73;
  width: 100%;
  border-radius: 12px;
  transition: all 0.3s ease-out;
}

.chart-bar__label {
  text-align: center;
}

.chart {
  border-radius: 12px;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: space-around;
  height: 10rem;
}

.TicketDiv {
  width: 95%;
  margin-left: 20px;
  margin-top: 16px;
  background: #fff;
  padding: 24px;
  border-radius: 12px;
}

.TicketDesc {
  width: 830px;
  height: 95%;
  margin-top: 16px;
  margin-bottom: 28px;
  padding: 24px;
  border-radius: 12px;
  background: #fff;
}

.profileSettingDiv {
  margin-top: 20px;
  margin-left: 20px;
  width: 971px;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
}

.accessRight {
  width: 547px;
}

.ms-20px {
  margin-left: 20px;
}

.ms-24 {
  margin-left: 24px;
}

.m36 {
  margin-top: 36px;
}

.m32 {
  margin-top: 32px;
}

.m30 {
  margin-top: 30px;
}

.m25 {
  margin-top: 25px;
}

.m24 {
  margin-top: 24px;
}

.m22 {
  margin-top: 22px;
}

.m20 {
  margin-top: 20px;
}

.m16 {
  margin-top: 16px;
}

.m12 {
  margin-top: 12px;
}

.m8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.m40 {
  margin-left: 40px;
}

.m48 {
  margin-left: 48px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt8 {
  margin-top: 8px;
}

.ml24 {
  margin-left: 24px;
}

.br-50 {
  border-radius: 50px;
}

@media (max-width: 1350px) {
  .BrandSteps {
    padding-top: 24px;
    padding-left: 12px;
    padding-bottom: 0;
    padding-right: 0;
  }
}

@media (max-width: 1550px) {
  .Added {
    display: none;
    max-width: 0;
  }

  .BrandSteps {
    margin-left: 10px;
  }
}

@media (max-width: 1620px) {
  .RecentDiv {
    width: 100%;
    padding-right: 30px;
  }

  .RecentSummaryChild {
    width: 50%;
  }

  .SummaryDiv {
    width: 89%;
    padding-right: 30px;
  }
}

table td {
  border-top-color: transparent !important;
}

.aside-dark .menu .menu-item .menu-link:focus .menu-title {
  color: #fff;
}

table tr th:last-child,
table tr td:last-child {
  padding-right: 2.5rem !important;
}

.table.table-row-dashed tr {
  border: 0px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  margin-top: 5px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 5px 10px !important;
  border: 0px !important;
  height: 40px !important;
  background: transparent !important;
}

.ant-slider-track {
  height: 7px;
  background-color: #261e3c !important;
}

.ant-slider-dot,
.ant-input {
  background-color: transparent !important;
  border: 0px !important;
}

.ant-slider-handle {
  background-color: #261e3c !important;
  border: solid 2px #261e3c !important;
}

.ant-slider-mark-text:last-child {
  left: 95% !important;
}

.ant-input-affix-wrapper {
  border: 0px !important;
  height: 40px;
  padding: 7px 11px !important;
}

.imageBadge {
  height: 45px;
  width: 45px;
  text-align: center;
  padding-top: 5px;
  font-size: 22px;
  background-color: #181c32 !important;
}

.view-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 253px;
  height: 431px;
  background: #ffffff;
  box-shadow: 0px 6px 24px 5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  margin: 20px 0 20px 20px;
}

.view-menu-image {
  display: flex;
  width: 100%;
  height: 82px;
  justify-content: space-between;
  border-radius: 60px;
}

.view-menu-image > img {
  width: 82px;
  height: 82px;
  object-fit: fill;
}

.view-menu-image > .icon {
  width: 20px;
  height: 16px;
  color: #1c2d41;
  cursor: pointer;
}

.brand {
  margin-top: 16px;
  padding: 0 10px;
}

.brand-name {
  height: 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin: 0px 0px;
  color: #1c2d41;
}

.brand-address {
  width: 169px;
  height: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0px 0px;
  margin-bottom: 12px;
}

.menu-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 206px;
}

.menu-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 206px;
  height: 21px;
  cursor: pointer;
  margin: 12px 0px;
}

.menu-options > p {
  width: 134px;
  height: 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
  margin: 0px 10px;
}

.menu-select > hr {
  width: 206px;
  height: 0px;
  border: 1px solid rgba(210, 213, 217, 0.25);
  margin: 0;
}

.view-profile {
  margin: 20px 0 20px 20px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 405px;
  background: #ffffff;
  border-radius: 12px;
}

.profile-detail {
  width: 337px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8e96a0;
  margin: 4px 0px;
}

.profile-detail > h2 {
  width: 317px;
  height: 21px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
}

.profile-detail > .about {
  height: 24px;
  line-height: 24px;
  font-size: 18px;
}

.profile-detail > p {
  width: 337px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8e96a0;
}

.profile-social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 168px;
  height: 69px;
  margin-top: 24px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 168px;
  height: 36px;
  display: flex;
}

.social-icons > .icon-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  width: 36px;
  height: 36px;
  border: 1px solid #f2f4f9;
  box-sizing: border-box;
  border-radius: 36px;
  margin-right: 8px;
}

.profile-joined {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 129px;
  height: 21px;
  margin: 12px 0px;
}

.profile-joined > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
}

.profile-joined > p > span {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
}

.profile-campaigns {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 405px;
  height: 531px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.07));
  border-radius: 12px;
  background: #ffffff;
  margin-top: 20px;
  padding: 24px;
}

.campaignHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 357px;
  height: 49px;
}

.campaignHeading > h2 {
  width: 337px;
  height: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
}

.campaignHeading > p {
  width: 357px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8e96a0;
}

.campaign-pie {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 275px;
  width: 100%;
  margin: 24px 0;
  border-radius: 50%;
}

.campaign-pie > div > p {
  text-align: center;
  color: #1c2d41;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.campaign-pie-markers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  width: 357px;
  height: 111px;
}

.campaign-pie-marker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-pie-marker > p {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
  margin: 0;
}

.campaign-pie-marker > p > div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;
}

.view-chart {
  margin: 20px;
  width: 755px;
  min-width: 600px;
}

.influencers-chart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  height: 275px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.07));
  border-radius: 12px;
  background: #ffffff;
}

.inf-chart-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.inf-chart-heading-left {
  height: 45px;
  width: 210px;
  display: flex;
  flex-direction: column;
}

.inf-chart-heading-left > h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.inf-chart-heading-left > p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8e96a0;
  margin: 0;
}

.inf-chart-heading-right {
  display: flex;
  align-items: center;
  height: 18px;
  width: 340px;
}

.inf-chart-heading-right > p {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1c2d41;
  margin-right: 16px;
}

.inf-chart-heading-right > p > div {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  margin-right: 5px;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 367px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.07));
  border-radius: 12px;
  background: #ffffff;
  margin-top: 20px;
}

.chart-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  height: 77px;
}

.chart-heading > h2 {
  width: 357px;
  height: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
}

.chart-heading > p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #8e96a0;
}

.chart-topbar {
  width: 100%;
  height: 83px;
  background: #f9fafc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
}

.chart-topbar-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-topbar-left > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #8e96a0;
  margin: 0;
}

.chart-topbar-left > p > span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1c2d41;
}

.chart-topbar-right {
  display: flex;
  align-items: center;
}

.chart-topbar-right > button {
  padding: 4px 8px;
  width: 55px;
  height: 26px;
  color: #8e96a0;
  border-radius: 6px;
  border: none;
}

.chart-topbar-right > button.active {
  background-color: #1c2d41;
  color: #ffffff;
}

.bg-purple {
  background-color: #513a98 !important;
  color: #ffffff;
  border-radius: 59px !important;
}

.chart-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-body > h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #1c2d41;
  margin: 8px 0;
}

.chart-body > p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #495767;
  margin: 0;
}

.chart-body > button {
  margin-top: 16px;
  width: 199px;
  height: 45px;
  background: #e72281;
  border-radius: 6px;
  padding: 12px 20px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.view-Bill {
  width: 1255px;
  margin: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-shadow: 0px 6px 24px 5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
}

.Bill-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  background-color: #fefefe;
  border-radius: 12px 12px 0px 0px;
}

.topbar-left {
  display: flex;
  align-items: center;
}

.topbar-left1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 16px;
}

.topbar-left1 > h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.topbar-left1 > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0;
}

.topbar-left2 {
  display: flex;
  align-items: flex-start;
  padding: 0px;
  border-radius: 4px;
}

.topbar-left2 > button {
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  background-color: #fff;
}

.topbar-left2 > button:hover,
.topbar-left2 > button.active {
  background-color: #513a98;
  color: #ffffff;
  border-radius: 64px;
  font-weight: 600;
}

.topbar-right {
  display: flex;
  align-items: center;
  padding: 0px;
  margin-left: 32px;
}

.bill-table {
  width: 100%;
}

.bill-row {
  width: 100%;
  height: 64px;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #1c2d41;
}

.View-brands {
  width: 1255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 20px;
}

.Brands-topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #fff;
  box-shadow: 0px 6px 24px 5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  margin-bottom: 16px;
}

.Brands-topbar-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Brands-topbar-left > h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.Brands-topbar-left > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0;
}

.Brands-topbar-right {
  display: flex;
  align-items: center;
  padding: 0px;
}

.Brands-container {
  width: 100%;
  padding: 0 !important;
}

.Brand-card {
  min-width: 205px !important;
  max-width: 235px !important;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin: 0 10px 20px 10px !important;
}

.Brand-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 131px;
}

.Brand-card-header > img {
  width: 82px;
  height: 82px;
  object-fit: fill;
}

.Brand-card-header > h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #1c2d41;
  margin: 0;
  margin-top: 12px;
}

.Brand-card-header > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #495767;
  margin: 0;
}

.Brand-card-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;
}

.Brand-card-detailsCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Brand-card-detailsCol > h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
  margin: 0;
}

.Brand-card-detailsCol > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0;
}

.view-Campaigns {
  width: 1255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 20px;
}

.Campaigns-topbar {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff;
  box-shadow: 0px 6px 24px 5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
}

.Campaigns-topbar-left {
  display: flex;
  align-items: center;
  height: 45px;
}

.Campaigns-topbar-left > button {
  border: none;
  min-width: 90px;
  height: 45px;
  padding: 12px 20px;
  border-radius: 4px;
  margin-right: 8px;
  color: #8e96a0;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.Campaigns-topbar-left > button:hover,
.Campaigns-topbar-left > button.active {
  background-color: #513a98;
  color: #ffffff;
  border-radius: 64px;
}

.Campaigns-topbar-right {
  display: flex;
  align-items: center;
  padding: 0px;
}

.Campaigns-body {
  width: 100%;
  padding: 0 !important;
  margin-top: 20px;
}

.Campaigns-card {
  min-width: 360px !important;
  max-width: 405px !important;
  height: 335px;
  padding: 24px !important;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin: 0 10px 20px 10px !important;
}

.Campaigns-card-header {
  display: flex;
  flex-direction: column;
}

.Campaigns-card-header > h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
  margin-bottom: 4px;
}

.Campaigns-card-header > p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #495767;
  margin: 0;
}

.Campaigns-card-body {
  margin: 16px 0;
  padding: 0 !important;
}

.Campaigns-card-body > div > div > h2 {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0;
}

.Campaigns-card-body > div > div > p {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1c2d41;
}

.Campaigns-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Campaigns-card-footer > p {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #8e96a0;
  margin: 0;
}

.influencer-view {
  width: 1255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px !important;
  margin: 20px !important;
}

.influencer-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  cursor: pointer;
  min-width: 360px !important;
  max-width: 405px !important;
  box-sizing: border-box;
  margin: 0 10px 20px 10px !important;
}

.view-questions {
  width: 1255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px !important;
  margin: 20px !important;
}

.question-card {
  width: 100%;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.question-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.question-input {
  width: 446px;
}

.question-input-checkbox {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px !important;
}

.checkbox-col {
  min-width: 331px !important;
  height: 65px;
  background: #f9fafc;
  border: 1px solid rgba(210, 213, 217, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px !important;
  margin: 16px 8px 0 8px !important;
  display: flex;
  align-items: center;
}

.checkbox-col > input {
  width: 16px;
  height: 16px;
  background: #eceff5;
  border-radius: 4px !important;
  margin-right: 10px;
  accent-color: #24a25c;
}

.checkbox-col > label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c2d41;
}

.question-input-button {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.question-input-button > button {
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  background: rgba(210, 213, 217, 0.25);
  color: #60666d;
  padding: 12px 20px;
}

.question-input-button > .active {
  background: #24a25c;
  color: #ffffff;
}

.campaign-approveToggle {
  width: 100%;
  height: 85px;
  padding: 16px 24px;
  background-color: #f9fafc;
}

.btn-approveToggle {
  border-radius: 75px !important;
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.review-status {
  background-color: #f9f1d580;
  color: #e3bb2f;
  width: 100%;
  padding: 12px;
  border: 2px dashed #e9c959;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.header-select-box {
  height: fit-content;
  margin-top: 12px;
}

.success-contract-button {
  background: rgba(213, 244, 227, 0.25) !important;
  border: 1px solid #2dca73 !important;
}

.modal {
  z-index: 1050 !important;
}

/* toggle switch css */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #513a98;
}

input:focus + .slider {
  box-shadow: 0 0 1px #513a98;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.grayout {
  filter: blur(3px);
}

.disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

.ant-pagination-options {
  display: none !important;
}

.influencer-thumbnail {
  height: 100px;
  background-size: cover;
  background-position: center center;
}

.form-select {
  border: 1px solid #f2f4f9;
  border-radius: 6px;
}

.campaign-description {
  text-overflow: ellipsis;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
}

.hide {
  display: none;
}

.text-align-right {
  text-align: right;
}

.ageGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 100px;
  height: 45px;

  /* Flake/25% */

  background: #f9fafc;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.verify-platform-buttons {
  float: right;
  margin-right: 8px;
}
