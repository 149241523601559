/* ---------------------------  Agency Dashboard Css  ---------------------------------- */
.Outer {
  .AgcyContainer {
    display: flex;
    flex-direction: column;
    width: 1518px;
    // height: auto;
    margin: 20px auto !important;

    .Top-Card-Container {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .Top-Influencer-Box {
        display: flex;
        flex-direction: column;
        padding: 24px;
        width: 492px;
        height: auto;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
        border-radius: 8px;

        h2 {
          color: #1c2d41;
          font-weight: 600;
          font-size: 18px;
        }

        thead,
        th {
          width: 500px;
        }

        .row-card {
          width: 500px;

          span {
            color: #8e96a0;
            font-weight: 400;
            font-size: 12px;
            margin-left: 10px;
          }

          .header-text {
            display: flex;
            align-items: center;
            justify-content: end;
          }
        }

        .User_row {
          .name {
            width: 900px;
          }

          .instra {
            text-align: center;
          }

          span {
            color: #1c2d41;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
    .Agcy-top {
      display: flex;
      width: 1538px;
      justify-content: space-between;
      // margin-bottom: 20px;
    }
    .AgcyCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px;
      margin-right: 20px;
      width: 291.6px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      .content-box {
        display: flex;
        .text-content {
          h2 {
            color: #1c2d41;
            font-weight: 600;
            font-size: 16px;
          }
          p {
            color: #8e96a0;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
      .info-value {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        width: 100%;
        height: auto;
        // background-color: rebeccapurple;
        h2 {
          color: #1c2d41;
          font-weight: 500;
          font-size: 36px;
        }
        p {
          color: #000000;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
// -----------------------------  Top Campaigns ------------------------------------
.Top-Campaign-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 20px auto !important;
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  h2 {
    color: #1c2d41;
    font-weight: 600;
    font-size: 18px;
  }
  .row-card {
    span {
      color: #8e96a0;
      font-weight: 400;
      font-size: 12px;
      // margin-left: 10px;
    }
    .row-card {
      span {
        color: #8e96a0;
        font-weight: 400;
        font-size: 12px;
        // margin-left: 10px;
      }
      .header-text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .agcy_header-text {
        display: flex;
        align-items: center;
        justify-content: start;
      }
    }
    .User_row {
      width: 1538px;
      .instra {
        text-align: end;
      }
      .agcy_text {
        text-align: start;
      }
    }
  }
}

// ------------------- End Card ------------------------------

.Dcard {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 24px;
  margin: 5px;
}

.p16 {
  padding: 16px !important;
}

.Dheading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1c2d41;
}

.Davatar {
  width: 32px;
  height: 32px;
  border-radius: 40px;
  margin-right: 8px;
}

.DheadingBig {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #1c2d41;
  margin: 0;
}

.DcustomHeading {
  font-weight: 600;
  font-size: 36px;
  line-height: 32px;
}

.Dempty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 335px;
}

.Outer > .Dgeneral,
.Outer > .Dbrand,
.Outer > .Dagency,
.Outer > .Dinfluencer {
  margin: 20px auto !important;
}

.Dagency,
.Dbrand,
.Dgeneral,
.Dinfluencer {
  display: flex;
  flex-direction: column;
  width: 1400px;
  margin: 20px;
}

.Dagency-top,
.Dbrand-top,
.Dgeneral-top,
.Dinfluencer-top {
  display: flex;
  width: 1400px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Dagency-bottom,
.Dbrand-bottom,
.Dgeneral-bottom,
.Dinfluencer-bottom {
  width: 1400px;
  margin-top: 20px;
  align-items: flex-start;
}

.Dcampaigns {
  width: 1400px;
}

.Dtransaction,
.Dbrands,
.Dagencies,
.DviewApp,
.Dengagement {
  width: 620px;
  margin-right: 20px;
}

.Dschedule,
.Dinfluencers,
.DtransactionL,
.Drecentadd {
  width: 760px;
}

.Dash-left {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.Dash-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Dash-right-top,
.Dash-left-top {
  display: flex;
  width: 100%;
  min-width: 150px;
  margin-bottom: 16px;
}

.Dash-right-image,
.Dash-left-image {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 12px;
}

.Dash-right-head,
.Dash-left-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dash-right-head > h2,
.Dash-left-head > h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.Dash-right-head > p,
.Dash-left-head > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin: 0;
}

.Dash-right-bottom,
.Dash-left-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dash-right-detail,
.Dash-left-detail {
  display: flex;
  align-items: center;
}

.Dash-right-detail > p,
.Dash-left-detail > p {
  margin: 0;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.Dinfluencer-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Dinfluencer-right-top {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.Dinfluencer-right-img {
  background: #f9fafc;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 12px;
}

.Dinfluencer-right-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dinfluencer-right-head > h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1c2d41;
  margin: 0;
}

.Dinfluencer-right-head > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8e96a0;
  margin-bottom: 20px;
}

.Dinfluencer-right-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dinfluencer-right-detail {
  display: flex;
  align-items: center;
}

.Dinfluencer-right-detail > p {
  margin: 0;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.Dash-Navbar {
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
}

.Dash-Navbar > button {
  border: none;
  min-width: 90px;
  height: 41px;
  padding: 12px 20px;
  border-radius: 49px;
  margin: 0 8px;
  background-color: #ffffff;
  color: #8e96a0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.Dash-Navbar > button:hover,
.Dash-Navbar > button.active {
  background-color: #513a98;
  color: #ffffff;
}
